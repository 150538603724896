<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>账号管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-button @click="addrole('new', '')" size="small">添加角色</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column label="编号" prop="id"></el-table-column>
      <el-table-column label="角色" prop="name"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column width="310">
        <template slot-scope="scope">
          <div class="opt doc">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="addrole('bj', scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="success"
              icon="el-icon-setting"
              @click="addPower(scope.row)"
              >设置权限</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 角色 -->
    <el-dialog :title="title1" :visible.sync="addroletitle">
      <el-form style="width: 80%;">
        <el-form-item label="角色:" label-width="180px">
          <el-input v-model="form.name" placeholder="请输入角色名"></el-input>
        </el-form-item>
        <el-form-item label="备注:" label-width="180px">
          <el-input
            v-model="form.remark"
            type="textarea"
            :rows="5"
            placeholder="请输入备注"
            resize="none"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addroletitle = false">取 消</el-button>
        <el-button type="primary" @click="saverole">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 设置权限 -->
    <el-dialog title="设置权限" :visible.sync="Createpower" @close="closesetpower">
      <el-form style="width: 80%;">
        <el-form-item label="选择可操作的权限:" label-width="180px">
          <el-tree
            v-loading="loading"
            :data="powerdata"
            show-checkbox
            node-key="id"
            accordion
            ref="tree"
            highlight-current
            :props="defaultProps"
            @check="nodeclick"
          >
          </el-tree>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closesetpower">取 消</el-button>
        <el-button type="primary" @click="savepower">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  inject: ["reload"],
  data() {
    return {
      list: [],
      Createpower: false,
      powerdata: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      powerchecklist: [],
      itempower: "",
      loading: false,
      title1: "添加角色",
      addroletitle: false,
      form: {
        name: "",
        remark: "",
      },
      roletype: "",
      rolerow: "",
    };
  },
  mounted() {
    this.getdata();
    this.getmenu();
  },
  methods: {
    addrole(type, row) {
      this.addroletitle = true;
      if (type == "new") {
        this.title1 = "添加角色";
        this.form.name = "";
        this.form.remark = "";
      } else {
        this.title1 = "编辑角色";
        this.form.name = row.name;
        this.form.remark = row.remark;
      }
      this.roletype = type;
      this.rolerow = row;
    },
    saverole() {
      let url = "";
      let str = "";
      let form = {
        name: this.form.name,
        remark: this.form.remark,
      };
      if (this.roletype == "new") {
        url = "/ma/insert_gu_ma_role";
        str = "添加成功";
      } else {
        url = "/ma/update_gu_ma_role";
        str = "修改成功";
        form.id = this.rolerow.id;
      }
      this.axios.post(url, this.qs.stringify(form)).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.reload();
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
        this.addroletitle = false;
      });
    },
    getmenu() {
      this.axios.get("/ma/get_gu_ma_user_right").then((res) => {
        if (res.data.code == 0) {
          if (res.data.code == 0) {
            var arr = res.data.result;
            arr.forEach((item) => {
              if ("children" in item) {
                this.powerdata.push(item);
              }
            });
          }
          // this.powerdata = res.data.result;
        }
      });
    },
    getdata() {
      this.axios
        .get("/ma/get_gu_ma_role")
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closesetpower() {
      this.Createpower = false;
      this.$refs.tree.setCheckedKeys([]);
    },
    addPower(row) {
      this.itempower = row;
      this.Createpower = true;
      var list = [];
      this.axios
        .get("/ma/get_gu_ma_role_right_by_role?role_id=" + row.id)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.result.length > 0) {
              let arr = res.data.result;
              for (let i = 0; i < arr.length; i++) {
                const element = arr[i];
                list.push(element.id);
                if ("children" in element) {
                  var list2 = element.children;
                  for (let m = 0; m < list2.length; m++) {
                    const ele = list2[m];
                    list.push(ele.id);
                  }
                }
              }
              list.forEach((value) => {
                this.$refs.tree.setChecked(value, true, false);
              });
            }
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    savepower() {
      let url = "/ma/insert_gu_ma_role_right";
      let str = "";
      for (let a = 0; a < this.powerchecklist.length; a++) {
        str += this.powerchecklist[a] + ",";
      }
      let form = {
        role_id: this.itempower.id,
        right_ids: str,
      };
      this.axios
        .post(url, this.qs.stringify(form))
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
          } else {
            this.$message({
              type: "info",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.Createpower = false;
    },
    nodeclick(a, b) {
      let parentArr = this.$refs.tree.getHalfCheckedKeys();
      let childArr = this.$refs.tree.getCheckedKeys();
      let arr = parentArr.concat(childArr);
      this.powerchecklist = arr;
    },
  },
};
</script>
<style>
.add {
  width: 100%;
  height: 50px;
  text-align: left;
}
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
</style>
